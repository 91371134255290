.Reason {
  @media (max-width: 768px) {
    flex-direction: column;

    #left {
      overflow: hidden;

      :nth-child(1) {
        width: 7rem;
        height: 17rem;
      }
      :nth-child(2) {
        width: 15rem;
        height: 10rem;
      }
      :nth-child(3) {
        width: 7rem;
      }
      :nth-child(4) {
        width: 7rem;
        height: 6rem;
      }
    }

    .span-right {
      justify-content: center;
      text-align: center;
    }

    #right-h {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 10px;
    }

    #partners {
      text-align: center;
      font-size: 40px;
    }

    .partners {
      display: flex;
      justify-content: center;
    }
  }

  padding: 0 2rem;
  display: flex;
  grid-template: 2rem;

  .left-r {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;

    img {
      object-fit: cover;
    }
    :nth-child(1) {
      width: 14rem;
      grid-row: 1/3;
      height: 28rem;
    }
    :nth-child(2) {
      width: auto;
      height: 16rem;
      grid-column: 2/4;
    }
    :nth-child(3) {
      width: 14rem;
      grid-column: 2/3;
      grid-row: 2;
    }
    :nth-child(4) {
      width: 10rem;
      grid-row: 2;
      grid-column: 3/4;
      height: 11.2rem;
    }
  }

  .right-r {
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    .span-right {
      color: var(--orange);
    }

    span {
      font-weight: bold;
    }

    div {
      color: white;
      font-size: 3rem;
      font-weight: bold;
    }

    .details-r {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      div {
        display: flex;
        font-size: 1rem;
        gap: 1rem;
      }

      img {
        width: 2rem;
        height: 2rem;
      }

      .partners {
        display: flex;
        gap: 1rem;

        img {
          width: 3.5rem;
        }
      }
    }
  }
}
