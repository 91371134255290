.plans-container {
  margin-top: -4rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;

  .blur-p {
    width: 30rem;
    height: 30rem;
    right: 0;
    bottom: -3rem;
  }

  .blur-p-two {
    width: 30rem;
    height: 30rem;
    left: 0;
    bottom: -3rem;
  }

  .plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;

    :nth-child(2) {
      svg {
        fill: white;
      }

      button {
        color: orange;
      }
    }
  }

  .plan {
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;

    &:nth-child(2) {
      background: var(--planCard);
      transform: scale(1.1);
    }

    svg {
      fill: var(--orange);
      width: 2rem;
      height: 2rem;
    }

    > :nth-child(2) {
      font-size: 1rem;
      font-weight: bold;
    }
    > :nth-child(3) {
      font-size: 3rem;
      font-weight: bold;
    }
    > :nth-child(5) {
      font-size: 0.8rem;
    }

    .feature {
      display: flex;
      align-items: center;
      gap: 1rem;

      img {
        width: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    .h2 {
      flex-direction: column;
      text-align: center;
      font-size: 30px;
      gap: 1rem;
      margin-top: 50px;
    }

    .plans {
      flex-direction: column;
      gap: 1rem;

      & > :nth-child(2) {
        transform: scale(1);
      }
    }
  }
}
