.hero {
  display: flex;
  justify-content: space-between;

  .blur-h {
    width: 30rem;
    height: 30rem;
    left: 0;
  }

  .left-side {
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;

    .the-best-ad {
      background-color: rgb(66, 66, 66);
      border-radius: 4rem;
      width: fit-content;
      padding: 20px 12px;
      text-transform: uppercase;
      color: white;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4rem;

      div {
        position: absolute;
        background-color: orange;
        width: 5.4rem;
        height: 80%;
        left: 8px;
        border-radius: 3rem;
      }

      span {
        z-index: 1;
      }
    }

    .hero-text {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      text-transform: uppercase;
      font-size: 4.5rem;
      font-weight: bold;
      color: white;
      text-overflow: inherit;

      div:nth-of-type(3) {
        font-size: 1rem;
        font-weight: 200;
        text-transform: none;
        letter-spacing: 1px;
      }

      .span {
        margin-top: 20px;
        font-size: 1rem;
        font-weight: 200;
        letter-spacing: 1px;
        text-transform: none;
      }
    }
    .figures {
      display: flex;
      gap: 2rem;

      div {
        display: flex;
        flex-direction: column;
      }

      span:nth-of-type(1) {
        color: white;
        font-size: 2rem;
        font-weight: 200;
      }

      span:nth-of-type(2) {
        color: var(--gray);
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 100;
      }
    }

    .hero-buttons {
      display: flex;
      gap: 1rem;
      font-weight: normal;

      :nth-child(1) {
        color: white;
        background-color: var(--orange);
        width: 8rem;
      }

      :nth-child(2) {
        color: white;
        background-color: transparent;
        width: 8rem;
        border: 2px solid var(--orange);
      }
    }
  }

  .right-side {
    flex: 1;
    position: relative;
    background-color: var(--orange);

    button {
      position: absolute;
      right: 3rem;
      margin-top: 20px;
      top: 2rsem;
      color: black;
    }
    .heart-rate {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: var(--darkGrey);
      width: fit-content;
      padding: 1rem;
      align-items: start;
      border-radius: 5px;
      position: absolute;
      right: 4rem;
      top: 7rem;

      img {
        width: 30px;
      }

      span:nth-child(2) {
        color: var(--gray);
      }
      span:nth-child(3) {
        color: white;
        font-size: 1.5rem;
      }
    }

    .hero {
      position: absolute;
      top: 9rem;
      right: 8rem;
      width: 23rem;
    }

    .hero_back {
      position: absolute;
      top: 4rem;
      right: 20rem;
      width: 15rem;
      z-index: -1;
    }

    .calories {
      background-color: var(--caloryCard);
      padding: 10px;
      display: flex;
      gap: 2rem;
      border-radius: 5px;
      width: 50%;
      position: absolute;
      top: 32rem;
      right: 28rem;

      img {
        width: 3rem;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      span:nth-child(1) {
        color: var(--gray);
      }
      span:nth-child(2) {
        color: white;
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    & {
      flex-direction: column;

      .blur-h {
        width: 14rem;
      }

      .figures {
        div {
          font-size: 1.5rem;
        }
      }

      .the-best-ad {
        margin-top: 0;
        font-size: small;
        align-self: center;

        transform: scale(0.8);
      }

      .hero-text {
        justify-self: center;
        text-align: center;
      }

      #css {
        margin-top: 0;
      }
      .pidor {
        font-size: xx-large;
        text-align: center;
      }

      .span {
        font-size: small;
        font-weight: 200;
        text-align: center;
        letter-spacing: 1px;
        font-size: 4.5rem;
      }

      .hero-buttons {
        justify-content: center;
      }

      .right-side {
        position: relative;
        background: none;

        .heart-rate {
          left: 1rem;
          top: 1rem;
        }

        .calories {
          position: relative;
          left: 5rem;
          top: 13rem;
        }

        .hero {
          position: relative;
          width: 15rem;
          left: 7rem;
          top: 12rem;
        }

        .hero_back {
          width: 15rem;
          left: 2rem;
        }
      }
    }
  }
}
