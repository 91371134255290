.Testimonials {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;

  .left-t {
    display: flex;
    width: 900px;
    margin-right: 300px;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;

    .h3 {
      justify-content: start;
    }

    > :nth-child(1) {
      color: var(--orange);
      font-weight: bold;
    }
    > :nth-child(2),
    :nth-child(3) {
      font-size: 3rem;
      font-weight: bold;
    }

    > :nth-child(4) {
      text-transform: none;
      text-align: justify;
      letter-spacing: 2px;
      line-height: 40px;
    }
  }

  .right-t {
    flex: 1;
    position: relative;

    > :nth-child(1) {
      position: absolute;
      width: 17rem;
      height: 20rem;
      border: 2px solid orange;
      background: transparent;
      right: 11;
      top: 0.9rem;
    }

    > img {
      position: absolute;
      width: 17rem;
      height: 20rem;
      object-fit: cover;
      right: 10rem;
      top: 2rem;
    }

    > :nth-child(2) {
      position: absolute;
      width: 17rem;
      height: 19rem;
      background: var(--planCard);
      right: 9rem;
      top: 4rem;
    }

    .arrows {
      display: flex;
      gap: 1rem;
      position: absolute;
      bottom: 1rem;
      left: -6rem;

      > img {
        width: 1.5rem;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .left-t {
      justify-content: center;
      text-align: center;
      width: 300px;
      gap: 1rem;
    }

    .right-t {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      div {
        position: relative;
        display: none;
      }

      img {
        position: relative;
        top: 0;
        right: 0.6rem;
        margin: 10px;

        align-self: center;
      }

      > :last-child {
        display: block;
        bottom: -3rem;
        left: 7rem;
        margin: 0 auto;
      }
    }
  }
}
