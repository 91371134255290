.Join {
  display: flex;
  padding: 0 2rem;
  font-size: 3rem;
  gap: 2rem;

  .left-j {
    display: flex;
    gap: 1.5rem;
    margin-right: 150px;

    hr {
      position: absolute;
      width: 10.5rem;
      border: 2px solid var(--orange);
      border-radius: 20%;
      margin: -10px 0;
    }
  }

  .riht-j {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .email-container {
      display: flex;
      gap: 3rem;
      background-color: gray;
      padding: 1rem 2rem;

      input {
        background-color: initial;
        border: none;
        outline: none;
        color: gray;
      }
      ::placeholder {
        color: var(--lightgray);
      }

      button {
        background-color: var(--orange);
        color: white;
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;

    span {
      font-size: 45px;
      flex-direction: column;
      gap: 1rem;
    }

    .right-j {
      padding: 2rem;
      margin-bottom: 0;
    }
  }
}
