.Programs {
  @media (max-width: 768px) {
    .h2 {
      margin-top: 150px;
      flex-direction: column;
      margin-top: 150px;
      gap: 1rem;
      text-align: center;
      font-size: x-large;
    }
    #categories {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;

  .programs-header {
    display: flex;
    gap: 2rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
  }

  .programm-categories {
    display: flex;
    gap: 1rem;
  }

  .category {
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;

    &:hover {
      background-color: var(--orange);
      cursor: pointer;
    }

    :nth-child(1) {
      height: 2rem;
      fill: white;
    }
    :nth-child(2) {
      font-size: 1rem;
      font-weight: bold;
    }
    :nth-child(3) {
      font-size: 0.9rem;
      font-weight: bold;
      line-height: 25px;
    }

    .join-now {
      display: flex;
      align-items: center;
      gap: 2rem;

      img {
        width: 1rem;
      }
    }
  }
}
