.header {
  display: flex;
  justify-content: space-between;

  img {
    width: 10rem;
    height: 3rem;
  }

  .header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;

    li:hover {
      cursor: pointer;
      color: rgb(222, 104, 0);
    }
  }

  @media (max-width: 768px) {
    img {
      width: 150px;
    }
    > :nth-child(2) {
      position: fixed;
      right: 2rem;
      z-index: 9;
    }

    .header-menu {
      flex-direction: column;
      padding: 2rem;
      background-color: var(--appColor);
    }
  }
}
